import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { UserListComponent } from './user-list/user-list.component';
import { UserListService } from './user-list/user-list.service';
import { UserViewComponent } from './user-view/user-view.component';
import { UserViewService } from './user-view/user-view.service';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserEditService } from './user-edit/user-edit.service';
import { NewUserSidebarComponent } from './user-list/new-user-sidebar/new-user-sidebar.component';
import { AuthGuard } from 'app/auth/helpers';
import { Role } from 'app/auth/models';

// routing
const routes: Routes = [
  {
    path: 'user/user-list',
    component: UserListComponent,
    canActivate: [AuthGuard],
    resolve: {
      uls: UserListService
    },
    data: { animation: 'UserListComponent', roles: [Role.Admin] }
  },
  {
    path: 'user/user-view/:id',
    component: UserViewComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: UserViewService
    },
    data: { path: 'user/view/:id', animation: 'UserViewComponent', roles: [Role.Admin] }
  },
  {
    path: 'user/user-edit/:id',
    component: UserEditComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: UserEditService
    },
    data: { animation: 'UserEditComponent', roles: [Role.Admin] }
  },
  {
    path: 'user/user-view',
    redirectTo: '/apps/user/user-view/2' // Redirection
  },
  {
    path: 'user/user-edit',
    redirectTo: '/apps/user/user-edit/2' // Redirection
  }
];

@NgModule({
  declarations: [UserListComponent, UserViewComponent, UserEditComponent, NewUserSidebarComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule
  ],
  providers: [
    UserListService, UserViewService, UserEditService
  ]
})
export class UserModule {}
