import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Apollo } from 'apollo-angular';
import userQueries from 'app/graphql/queries/user.queries';

import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class UserListService implements Resolve<any> {
  public rows: any;
  public onDatatablessChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo
  ) {
    // Set the defaults
    this.onDatatablessChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows()]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._apollo.query({
        query: userQueries.getTierReqtInVerification
      }).subscribe((response: any) => {
          this.rows = response['data']['user_tier_requirements'];
          this.onDatatablessChanged.next(this.rows);
          resolve(this.rows);
      }, reject)
      // this._httpClient.get('api/users-data').subscribe((response: any) => {
      //   this.rows = response;
      //   this.onDatatablessChanged.next(this.rows);
      //   resolve(this.rows);
      // }, reject);
    });
  }
}
