import { CoreMenu } from '@core/types'
import { Role } from 'app/auth/models'

export const menu: CoreMenu[] = [
  {
    id: 'Requisitos',
    title: 'Requisitos',
    translate: 'MENU.USERS',
    type: 'item',
    icon: 'home',
    url: 'user/user-list',
    role: [Role.Admin]
  }
]
