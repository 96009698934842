import gql from "graphql-tag";

const currentUser = gql`
  query CurrentUser {
    get_user_by_token {
      id
      display_name
      avatar_url
      account {
        role {
          role
        }
      }
      additional_data {
        _id
        address
        country_id
        phone_number
        document_type_id
        document
        first_name
        last_name
        data_attachments {
          url
        }
      }
    }
  }
`;

const getTierReqtInVerification = gql`
  query GetTierReqtInVerification {
    user_tier_requirements(where: {status: {_eq: "VERIFYING"}}) {
      id
      link
      status
      tier_requirement {
        name
        code
        id
      }
      userTier {
        User {
          display_name
          avatar_url
          tier_amount
          tier {
            id
            name
            amount
          }
        }
      }
      observation
      updated_at
    }
  }
`;

const getTierReqtInVerificationById = gql`
  query GetTierReqtInVerificationById(
    \$id: String
  ) {
    user_tier_requirements(where: {status: {_eq: "VERIFYING"}, id: {_eq: \$id}}) {
      id
      link
      status
      tier_requirement {
        name
        code
        id
      }
      userTier {
        User {
          display_name
          avatar_url
          tier_amount
          tier {
            id
            name
            amount
          }
        }
      }
      observation
      updated_at
    }
  }
`;

const acceptOrRejectReq = gql`
  mutation acceptOrRejectReq (
    \$reqId: String!,
    \$status: String,
    \$observation: String,
  ) {
    update_user_tier_requirements_by_pk(
      pk_columns: {
        id: \$reqId
      }, 
      _set: {
        status: \$status, 
        observation: \$observation
      }
    ) {
      id
    }
  }
`;

export default {
  currentUser,
  getTierReqtInVerification,
  getTierReqtInVerificationById,
  acceptOrRejectReq
}