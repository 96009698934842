<div class="slideout-content">
  <!-- Modal to add new user starts-->
  <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <form class="add-new-user modal-content pt-0" (ngSubmit)="(newUserForm.form.valid)" #newUserForm="ngForm">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="toggleSidebar('new-user-sidebar')"
        >
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title" id="exampleModalLabel">New User</h5>
        </div>
        <div class="modal-body flex-grow-1">
          <div class="form-group">
            <label class="form-label" for="basic-icon-default-fullname">Full Name</label>
            <input
              type="text"
              class="form-control dt-full-name"
              id="basic-icon-default-fullname"
              placeholder="John Doe"
              name="user-fullname"
              [(ngModel)]="fullname"
              [class.error]="newUserForm.submitted && FNameRef.invalid"
              #FNameRef="ngModel"
              required
              aria-label="John Doe"
              aria-describedby="basic-icon-default-fullname2"
            />
            <span *ngIf="newUserForm.submitted && FNameRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FNameRef.errors.required">This field is required!</small>
            </span>
          </div>
          <div class="form-group">
            <label class="form-label" for="basic-icon-default-uname">Username</label>
            <input
              type="text"
              id="basic-icon-default-uname"
              class="form-control dt-uname"
              placeholder="Web Developer"
              aria-label="jdoe1"
              aria-describedby="basic-icon-default-uname2"
              name="user-name"
              [(ngModel)]="username"
              [class.error]="newUserForm.submitted && FUserNameRef.invalid"
              #FUserNameRef="ngModel"
              required
            />
            <span *ngIf="newUserForm.submitted && FUserNameRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FUserNameRef.errors.required">This field is required!</small>
            </span>
          </div>
          <div class="form-group">
            <label class="form-label" for="basic-icon-default-email">Email</label>
            <input
              type="text"
              id="basic-icon-default-email"
              class="form-control dt-email"
              placeholder="john.doe@example.com"
              aria-label="john.doe@example.com"
              aria-describedby="basic-icon-default-email2"
              name="user-email"
              [(ngModel)]="email"
              [class.error]="newUserForm.submitted && FEmailRef.invalid"
              #FEmailRef="ngModel"
              required
            />
            <span *ngIf="newUserForm.submitted && FEmailRef.invalid" class="invalid-form">
              <small class="form-text text-danger" *ngIf="FEmailRef.errors.required">This field is required!</small>
            </span>
            <small class="form-text text-muted"> You can use letters, numbers & periods </small>
          </div>
          <div class="form-group">
            <label class="form-label" for="user-role">User Role</label>
            <select id="user-role" class="form-control">
              <option value="subscriber">Subscriber</option>
              <option value="editor">Editor</option>
              <option value="maintainer">Maintainer</option>
              <option value="author">Author</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div class="form-group mb-2">
            <label class="form-label" for="user-plan">Select Plan</label>
            <select id="user-plan" class="form-control">
              <option value="basic">Basic</option>
              <option value="enterprise">Enterprise</option>
              <option value="company">Company</option>
              <option value="team">Team</option>
            </select>
          </div>
          <button type="submit" class="btn btn-primary mr-1" (click)="submit(newUserForm)" rippleEffect>Submit</button>
          <button
            type="reset"
            class="btn btn-outline-secondary"
            (click)="toggleSidebar('new-user-sidebar')"
            rippleEffect
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- Modal to add new user Ends-->
</div>
