import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { LoginResponse } from '@core/entities/login-response';
import userQueries from 'app/graphql/queries/user.queries';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient, 
    private _toastrService: ToastrService,
    private _apollo: Apollo
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    console.log(email, password);
    
    return this._http
      .post<LoginResponse>(`${environment.apiUrl}/auth/login`, { email, password })
      .pipe(
        switchMap(user => {
          localStorage.setItem('token', user.jwt_token);
          return this._apollo.query({
              query: userQueries.currentUser
            }).pipe(
              map(currentUser => {
                return ({
                  currentUser: currentUser.data['get_user_by_token'][0], user
                });
              })
            )
          }
        ),
        map(({ currentUser, user}) => {
          
          // login successful if there's a jwt token in the response
          if (user && user.jwt_token) {
            const userA: User = {
              id: user.user.id,
              avatar: environment.storageUrl + currentUser['avatar_url'],
              email: user.user.email,
              firstName: user.user.display_name,
              role: currentUser['account']['role']['role'] === 'admin' ? Role.Admin :
                currentUser['account']['role']['role'] === 'user' ? Role.User : Role.Client,
            };

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(userA));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                '👋 Welcome, ' + user.user.display_name + '!', null,
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);
            // notify
            this.currentUserSubject.next(userA);
          }

          return user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
