import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Apollo } from "apollo-angular";
import userQueries from "app/graphql/queries/user.queries";

import { BehaviorSubject, Observable, of, zip } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class UserViewService implements Resolve<any> {
  public rows: any;
  public onDataChanged: BehaviorSubject<any>;
  public id;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient, private _apollo: Apollo) {
    // Set the defaults
    this.onDataChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    let currentId = route.params.id;
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getApiData(currentId)]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getApiData(id: string): Promise<any> {
    const url = `api/users-data/${id}`;

    return new Promise((resolve, reject) => {
      this._apollo
        .query({
          query: userQueries.getTierReqtInVerificationById,
          variables: {
            id: id,
          },
        })
        .pipe(
          switchMap((queryResult) => {
            const x = queryResult["data"]["user_tier_requirements"][0];
            const firstImage = x.link?.split(",")[0] || "";
            return this._httpClient
              .get(`https://auth.simplepayglobal.com/storage/m/${firstImage}`)
              .pipe(
                map((imageInfo) => ({ imageInfo, queryResult: x })),
                catchError((e) => of({ imageInfo: null, queryResult: x }))
              );
          })
        )
        .subscribe(({ imageInfo, queryResult }) => {
          this.onDataChanged.next({ imageInfo, queryResult });
          console.log(queryResult);

          resolve({ imageInfo, queryResult });
        }, reject);
    });
  }

  acceptOrReject({
    reqId,
    status,
    observation,
  }: {
    reqId: string;
    status: string;
    observation: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apollo
        .mutate({
          mutation: userQueries.acceptOrRejectReq,
          variables: {
            reqId,
            status,
            observation,
          },
        })
        .subscribe((queryResult) => {
          resolve({ queryResult });
        }, reject);
    });
  }
}
