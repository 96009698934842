import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UserViewService } from "./user-view.service";

@Component({
  selector: "app-user-view",
  templateUrl: "./user-view.component.html",
  styleUrls: ["./user-view.component.sass"],
  encapsulation: ViewEncapsulation.None,
})
export class UserViewComponent implements OnInit {
  // public
  public url = this.router.url;
  public lastValue;
  public data;
  public imageInfo;
  public observation;
  public modalImage;
  public showModal;

  // private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} router
   * @param {UserViewService} _userViewService
   */
  constructor(
    private router: Router,
    private _userViewService: UserViewService
  ) {
    this._unsubscribeAll = new Subject();
    this.lastValue = this.url.substr(this.url.lastIndexOf("/") + 1);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    this._userViewService.onDataChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response) => {
        this.data = response.queryResult;
        this.imageInfo = response.imageInfo;
      });
  }

  get userImageUrl(): string {
    const avatarUrl = this.data?.userTier?.User?.avatar_url;
    return this.data.userTier.User.avatar_url.includes("all/")
      ? environment.storageUrl + avatarUrl
      : avatarUrl;
  }

  get images(): string[] {
    return (
      this.data.link?.split(",").map((i) => environment.storageUrl + i) || []
    );
  }

  public setModalImage(image: string): void {
    this.modalImage = image;
    this.showModal = true;
  }

  public handleSubmit(status: "SUCCESS" | "REJECTED"): void {
    this._userViewService
      .acceptOrReject({
        reqId: this.data.id,
        status,
        observation: this.observation,
      })
      .then((r) => {
        this.goBack();
      });
  }

  public goBack(): void {
    this.router.navigate([".."]);
  }
}
