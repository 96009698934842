<div class="wrapper">
  <div class="user-info">
    <div class="tier-req">{{data.tier_requirement?.name}}</div>
    <div class="date">{{data?.updated_at | date: 'short'}}</div>
    <div class="data">
      <div class="figure">
        <img [src]="userImageUrl" alt="">
      </div>
      <div class="info">
        <div class="display-name">{{data.userTier?.User?.display_name}}</div>
        <div class="tier-name">{{data.userTier?.User?.tier?.name}}</div>
        <div class="tier-amounts">USD {{data.userTier?.User?.tier_amount}} / USD {{data.userTier?.User?.tier?.amount}}</div>
      </div>
    </div>
    <div class="observation">
      <textarea placeholder="Ingresa acá tus observaciones" [(ngModel)]="observation"></textarea>
    </div>
    <div class="buttons">
      <div class="btn btn-sm btn-primary button" (click)="goBack()">Cancelar</div>
      <div class="btn btn-sm btn-danger button reject" (click)="handleSubmit('REJECTED')">Rechazar</div>
      <div class="btn btn-sm btn-success button accept" (click)="handleSubmit('SUCCESS')">Aprobar</div>
    </div>
  </div>

  <div class="files">
    <figure *ngFor="let i of images" (click)="setModalImage(i)">
      <img [src]="i" alt="">
    </figure>
  </div>
</div>

<div class="modal" *ngIf="showModal" (click)="showModal = false">
  <div class="overflow"></div>
  <div class="body-content">
    <figure *ngIf="modalImage">
      <img [src]="modalImage" alt="Modal">
    </figure>
  </div>
</div>