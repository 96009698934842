<div class="content-wrapper">
  <div class="content-body">
    <!-- User List -->
    <section class="users-list-wrapper">
      <!-- users filter start -->
      <!-- <div class="card">
        <h5 class="card-header">Search Filter</h5>
        <div class="card-body">
          <div class="users-list-filter">
            <form>
              <div class="row">
                <div class="col-12 col-md-4">
                  <fieldset class="form-group">
                    <ng-select [items]="selectRole" [(ngModel)]="selectedRole" name="selectedRole" bindLabel="name" placeholder="Select Role" (change)="filterByRole($event)"></ng-select>
                  </fieldset>
                </div>
                <div class="col-12 col-md-4">
                  <fieldset class="form-group">
                    <ng-select [items]="selectPlan" [(ngModel)]="selectedPlan" name="selectedPlan" bindLabel="name" placeholder="Select Plan" (change)="filterByPlan($event)"></ng-select>
                  </fieldset>
                </div>
                <div class="col-12 col-md-4">
                  <fieldset class="form-group">
                    <ng-select [items]="selectStatus" [(ngModel)]="selectedStatus" name="selectedStatus" bindLabel="name" placeholder="Select Status" (change)="filterByStatus($event)"></ng-select>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> -->
      <!-- users filter end -->

      <div class="card">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center"
                >Show
                <select class="form-control mx-25" [(ngModel)]="selectedOption">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
              <label class="d-flex align-items-center ml-1 ml-md-0"
                >Search:<input [(ngModel)]="searchValue" name="searchValue" type="search" class="form-control ml-25" (keyup)="filterUpdate($event)"
              /></label>
              <!-- <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-user-sidebar')">
                <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                ><span class="d-none d-sm-inline-block">Add New User</span>
              </button> -->
            </div>
          </div>
        </div>
        <ngx-datatable
          [rows]="rows"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <ngx-datatable-column name="User" prop="fullName" [width]="300">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
            
                <div class="cell-line-height">
                  <a routerLink="/apps/user/user-view/{{ row.tier_requirement?.id }}" class="font-medium-1 d-block"
                    ><span class="font-weight-bold">{{ row.userTier?.User?.display_name }}</span></a
                  >
                  <span class="text-muted font-small-2">{{ row?.updated_at | date: 'short' }}</span>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="TierName" prop="userTier.User.tier.name" [width]="100"></ngx-datatable-column>
          <ngx-datatable-column name="TierRequirementName" prop="tier_requirement.name" [width]="200"></ngx-datatable-column>
          <ngx-datatable-column name="Status" prop="status" [width]="50"></ngx-datatable-column>
          <ngx-datatable-column name="Actions" [width]="40" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div ngbDropdown container="body">
                <a routerLink="/user/user-view/{{ row.id }}" ngbDropdownItem>
                  <span [data-feather]="'search'" [size]="16" [class]="'mr-50'"></span>
                </a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </section>
    <!--/ User List -->
  </div>

  <!-- New User Sidebar -->
  <core-sidebar
    class="modal modal-slide-in sidebar-todo-modal fade"
    name="new-user-sidebar"
    overlayClass="modal-backdrop"
  >
    <app-new-user-sidebar></app-new-user-sidebar>
  </core-sidebar>
  <!--/ New User Sidebar -->
</div>
