import { Component, OnInit } from '@angular/core'

import { CoreTranslationService } from '@core/services/translation.service'
import { locale as localeEn } from './i18n/en';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private _coreTranslationService: CoreTranslationService
  ) {
    _coreTranslationService.translate(localeEn);
  }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Approve users',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Approve users',
            isLink: true,
            link: '/'
          },
          {
            name: 'users',
            isLink: false
          }
        ]
      }
    }
  }
}
